/**
 * Represents the configuration for the company.
 */
export class CompanyConfig {
  /**
   * The address of the company.
   */
  static readonly ADDRESS = {};

  /**
   * The contact information of the company.
   */
  static readonly CONTACT = {
    phone: '+48 535 420 745',
    email: 'kontakt@e-waiter.pl',
  };

  /**
   * General information about the company.
   */
  static readonly GENERAL = {
    workingHours: '08.00 - 16.00',
    defaultCurrencyCode: 'zł',
    ewaiterWebsite: 'https://www.e-waiter.pl',
    wirtualnykelnerWebsite: 'https://www.wirtualnykelner.pl',
  };

  /**
   * Social media links of the company.
   */
  static readonly SOCIAL_MEDIA = {
    Facebook: 'https://www.facebook.com/ewaiterEU',
    Instagram: 'https://www.instagram.com/ewaiter__/',
    Tiktok: null,
  };

  /**
   * Links to the company's mobile apps.
   */
  static readonly APP_LINKS = {
    Apple: 'https://apps.apple.com/pl/app/e-waiter/id1518982193',
    Google: 'https://play.google.com/store/apps/details?id=com.primebitstudio.virtualwaiter',
  };
}
